import { useState, useEffect } from "react"
import PlanetView from "./PlanetView"
import { usePlanetById } from "data/queries/planet"
import { Timer } from "components/display"
import { useChallangePodium } from "data/queries/challange"
import QuadrantView from "./QuadrantView"
import RegionView from "./RegionView"
import styles from "./CentralNavigator.module.scss"

const CentralNavigator = () => {
  const [view, setView] = useState("planet")

  const planetId = localStorage.getItem("planet")
  const planetData = usePlanetById(planetId || "0")
  const planet =
    planetData instanceof Array ? planetData[0].data : planetData.data

  const podium = useChallangePodium()

  useEffect(() => {
    if (document.location.pathname.includes("/game/planet/")) {
      setView("planet")
    }
    if (document.location.pathname.includes("/game/quadrant/")) {
      setView("quadrant")
    }
    if (document.location.pathname.includes("/game/galaxy/")) {
      setView("region")
    }
  }, [planet])

  return (
    <div className={styles.central}>
      <div
        className={view === "region" ? styles.navLeftInactive : styles.navLeft}
        onClick={() => {
          if (view === "planet") {
            setView("quadrant")
          }
          if (view === "quadrant") {
            setView("region")
          }
        }}
      >
        <div className={styles.navLeftIco}></div>
      </div>
      <div className={`${styles.selector} controlPanel-center`}>
        {planet && (
          <>
            {view === "planet" && <PlanetView planet={planet} />}
            {view === "quadrant" && <QuadrantView />}
            {view === "region" && (
              <RegionView region={localStorage.getItem("region")} />
            )}
          </>
        )}
        <div className={styles.clock}>
          {podium.data &&
            Array.isArray(podium.data) &&
            typeof podium.data[0] === "object" &&
            podium.data[0] !== null &&
            podium.data[0].start && (
              <Timer end={(podium.data[0].start + 1296000) * 1000} />
            ) &&
            typeof podium.data[0] !== "object" && <Timer end={0} />}
        </div>
      </div>
      <div
        className={
          view === "planet" ? styles.navRightInactive : styles.navRight
        }
        onClick={() => {
          if (view === "quadrant") {
            setView("planet")
          }
          if (view === "region") {
            setView("quadrant")
          }
        }}
      >
        <div className={`${styles.navRightIco} navRightIco`}></div>
      </div>
    </div>
  )
}

export default CentralNavigator
