/* wallet */
export const BRIDGE = "https://walletconnect.terrarebels.net"

/* query */
export const LAZY_LIMIT = 999

const host_site = window.location.href

/* api */
export const TRITIUM =
  host_site.indexOf("localhost") > 0
    ? "http://localhost:8000"
    : host_site.indexOf("rigel") > 0
    ? "https://api-rigel.terratritium.com"
    : host_site.indexOf("sirius") > 0
    ? "https://api-sirius.terratritium.com"
    : host_site.indexOf("origin") > 0
    ? "https://api-origin.terratritium.com"
    : ""

/* placeholder */
// https://github.com/terra-money/localterra
export const SAMPLE_ADDRESS = "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v"
export const TRITIUM_NFT_ORIGINS =
  "terra18sj5hluytww5hmy4teqsr4a7qr66pcd2x3qm9ns96ucpkdyserpszlx3qw"

/** BACKEND */
export const TRITIUM_GALAXY =
  host_site.indexOf("localhost") > 0
    ? "Localhost"
    : host_site.indexOf("rigel") > 0
    ? "Rigel"
    : host_site.indexOf("sirius") > 0
    ? "Sirius"
    : host_site.indexOf("origin") > 0
    ? "Origin"
    : ""

let construction_speed = 100
let research_speed = 1
let production_speed = 10
let travel_speed = 300
let stage_speed = 10
let energy_base = 10
let energy_workers_by_level = 10
let metal_base = 10
let uranium_base = 5
let crystal_base = 5
let humanoid_base = 10
let humanoid_price = 500
let transportship_base = 1000
let transportship_price = 20000
let transportship_capacity = 500
let count_member_level_alliance = 5
let member_founder = 1
let member_general = 2
let member_fleet_cap = 3
let member_troop_cap = 4
let member_diplomat = 5
let member_corporal = 6
let member_soldier = 7
let chat_alliance = 1
let chat_other_alliance = 2
let market_status_open = 1
let market_status_canceled = 0
let market_status_pending = 3
let market_status_finished = 2
let max_planet_player = 7
let travel_mission_spionage_cost = 1000
let charging_speed = 100
let shield_force = 10

if (host_site.indexOf("rigel") > 0) {
}

if (host_site.indexOf("sirius") > 0) {
}

if (host_site.indexOf("localhost") > 0) {
}

export const TRITIUM_CONSTRUCTION_SPEED = construction_speed
export const TRITIUM_RESEARCH_SPEED = research_speed
export const TRITIUM_PRODUCTION_SPEED = production_speed
export const TRITIUM_TRAVEL_SPEED = travel_speed
export const TRITIUM_STAGE_SPEED = stage_speed
export const TRITIUM_ENERGY_BASE = energy_base
export const TRITIUM_ENERGY_WORKERS_BY_LEVEL = energy_workers_by_level
export const TRITIUM_METAL_BASE = metal_base
export const TRITIUM_URANIUM_BASE = uranium_base
export const TRITIUM_CRYSTAL_BASE = crystal_base
export const TRITIUM_HUMANOID_BASE = humanoid_base
export const TRITIUM_HUMANOID_PRICE = humanoid_price
export const TRITIUM_TRANSPORTSHIP_BASE = transportship_base
export const TRITIUM_TRANSPORTSHIP_PRICE = transportship_price
export const TRITIUM_TRANSPORTSHIP_CAPACITY = transportship_capacity
export const TRITIUM_COUNT_MEMBER_LEVEL_ALIANCE = count_member_level_alliance
export const TRITIUM_MEMBER_FOUNDER = member_founder
export const TRITIUM_MEMBER_GENERAL = member_general
export const TRITIUM_MEMBER_FLEET_CAP = member_fleet_cap
export const TRITIUM_MEMBER_TROOP_CAP = member_troop_cap
export const TRITIUM_MEMBER_DIPLOMAT = member_diplomat
export const TRITIUM_MEMBER_CORPORAL = member_corporal
export const TRITIUM_MEMBER_SOLDIER = member_soldier
export const TRITIUM_CHAT_ALIANCE = chat_alliance
export const TRITIUM_CHAT_OTHER_ALIANCE = chat_other_alliance
export const TRITIUM_MARKET_STATUS_OPEN = market_status_open
export const TRITIUM_MARKET_STATUS_CANCELED = market_status_canceled
export const TRITIUM_MARKET_STATUS_PENDING = market_status_pending
export const TRITIUM_MARKET_STATUS_FINISHED = market_status_finished
export const TRITIUM_MAX_PLANET_PLAYER = max_planet_player
export const TRITIUM_TRAVEL_MISSION_SPIONAGE_COST = travel_mission_spionage_cost
export const TRITIUM_CHARGING_SPEED = charging_speed
export const TRITIUM_SHIELD_FORCE = shield_force

/** FRONTEND */
export const TRITIUM_MAX_LENGTH_MSG = 1000
export const TRITIUM_MSG_PREVIEW = 50
export const TRITIUM_TYPE_CRYSTAL = 1
export const TRITIUM_TYPE_METAL = 2
export const TRITIUM_TYPE_URANIUM = 3
export const TRITIUM_TYPE_ENERGY = 4
export const TRITIUM_SCREEN_TRADING = 1
export const TRITIUM_SCREEN_HISTORY = 2
export const TRITIUM_SCREEN_NEW_SALE = 3
export const TRITIUM_SCREEN_NEW_PURCH = 4
export const TRITIUM_SCREEN_FINISH_ORDER = 5
export const TRITIUM_SCREEN_NEW_ORDER = 6
export const TRITIUM_NEW_PURCH = false
export const TRITIUM_NEW_SALE = true
export const TRITIUM_PROMOVER = "Promover"
export const TRITIUM_REBAIXAR = "Rebaixar"
export const TRITIUM_DEIXAR_CARGO = "Exit"
export const TRITIUM_REMOVER_MEMBRO = "Remover"
export const TRITIUM_STRATEGY_DEFAULT = "7"
export const TRITIUM_TRAVEL_ATTACK_FLEET = 1
export const TRITIUM_TRAVEL_ATTACK_TROOP = 2
export const TRITIUM_TRAVEL_DEFENSE_FLEET = 3
export const TRITIUM_TRAVEL_TRANSPORT_RESOURCE = 5
export const TRITIUM_TRAVEL_TRANSPORT_BUY = 6
export const TRITIUM_TRAVEL_TRANSPORT_SELL = 7
export const TRITIUM_TRAVEL_MISSION_EXPLORER = 8
export const TRITIUM_TRAVEL_MISSION_SPIONAGE = 11
export const TRITIUM_TRAVEL_STATUS_ON_LOAD = 1
export const TRITIUM_TRAVEL_STATUS_ON_GOING = 2
export const TRITIUM_TRAVEL_STATUS_RETURN = 3
export const TRITIUM_TRAVEL_STATUS_FINISHED = 4
export const TRITIUM_TRAVEL_STATUS_CANCEL = 5
export const TRITIUM_MODE_CONQUER = 1
export const TRITIUM_MODE_COLONIZER = 2
export const TRITIUM_MODE_SPACE_TITAN = 3
export const TRITIUM_MODE_RESEARCHER = 4
export const TRITIUM_MODE_ENGINEER = 5
export const TRITIUM_MODE_PROTECTOR = 6
export const TRITIUM_MODE_BUILDER = 7
export const TRITIUM_MODE_NAVIGATOR = 8
export const TRITIUM_MODE_MINER = 9
export const TRITIUM_COST_MISSION_SPIONAGE = 100

export const BUILD_COLONIZATION = 1

/* Login */
export const TOKEN_KEY = "tritium-token"
export const TRITIUM_KEY_RECAPTCHA = "6LcUEZ8pAAAAAG7qPJuMt7B67u4OoCg97VIujDhe"
